import React from 'react'

import "../styles/general.css";

const Spinner = () => {

  return (
    <div className='spinner'></div>
  )
  };
export default Spinner

