const PROJECTS = [
    {
        header : {
            role: "",
            context: "",
            time: ""
        },
        body : {

        },
        nextWork : {
            title: "",
            image: "",
            link: "",
        }

    },

    {
        
    }


]

export default PROJECTS